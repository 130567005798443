import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Col, Container, Row } from "react-bootstrap"
import { Table } from "react-bootstrap"
import axiosInstance, { BETS_URL } from "../helpers/api"
import isAuthenticated from "../utils/isAuthenticated"

const styles = {
    bold: { fontWeight: 900 },
}

const getBets = (history) => {
    return axiosInstance(history)
        .get(BETS_URL)
        .then((res) => {
            let clean = res.data.bets
                .filter(
                    (entry) =>
                        "data" in entry &&
                        "other" in entry &&
                        "username" in entry
                )
                .map((entry) => {
                    return {
                        username: entry.username,
                        data: entry.other,
                        points: entry.points ? entry.points : 0,
                    }
                })
                .sort((a, b) => b.points - a.points)

            console.log(clean)
            return clean
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("accessToken")
                    history.push("/login")
                } else {
                    console.log(err.response.status)
                }
            }
        })
}

const BetsPage = () => {
    const history = useHistory()
    const [bets, setBets] = useState([])

    useEffect(() => {
        if (!isAuthenticated()) {
            history.push("/login")
        }

        getBets(history).then((data) => {
            if (data) {
                setBets(data)
            }
        })
    }, [])

    useEffect(() => {
        console.log(bets)
    }, [bets])

    return (
        <Container className="py-5">
            <Row className="justify-content-center align-items-center">
                <Col xs={12}>
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Final</th>
                                <th>Top Scorer</th>
                                <th>Points</th>
                                <th>B1-ADEF3</th>
                                <th>A1-C2</th>
                                <th>F1-ABC3</th>
                                <th>D2-E2</th>
                                <th>E1-ABCD3</th>
                                <th>D1-F2</th>
                                <th>C1-DEF3</th>
                                <th>A2-B2</th>
                                <th>QF1</th>
                                <th>QF2</th>
                                <th>QF3</th>
                                <th>QF4</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bets.map((bet, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{bet.username}</td>
                                    <td><Bet match={bet.data.finalMatch[0]} /></td>
                                    <td>{bet.data.scorer}</td>
                                    <td>{bet.points}</td>
                                    <td><Bet match={bet.data.knockoutMatches[0]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[1]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[2]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[3]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[4]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[5]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[6]} /></td>
                                    <td><Bet match={bet.data.knockoutMatches[7]} /></td>
                                    <td><Bet match={bet.data.quarterMatches[0]} /></td>
                                    <td><Bet match={bet.data.quarterMatches[1]} /></td>
                                    <td><Bet match={bet.data.quarterMatches[2]} /></td>
                                    <td><Bet match={bet.data.quarterMatches[3]} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

const Bet = ({ match }) => {
    return (
        <span style={{whiteSpace: "nowrap"}}>
            {match.winner === 0 ? (
                <>
                    <span style={styles.bold}>{match.teams[0].code}</span>
                    -{match.teams[1].code}
                </>
            ) : (
                <>
                    {match.teams[0].code}-
                    <span style={styles.bold}>{match.teams[1].code}</span>
                </>
            )}
        </span>
    )
}

export default BetsPage
