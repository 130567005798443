import isAuthenticated from "../utils/isAuthenticated"
import axios from "axios"

export default function (history = null) {
    const baseURL = process.env.REACT_APP_BACKEND_URL
    let headers = {}

    if (isAuthenticated()) {
        headers.Authorization = `${localStorage.accessToken}`
    }

    const axiosInstance = axios.create({
        baseURL,
        headers,
    })

    return axiosInstance
}

export const BET_URL = "/bets"
export const BETS_URL = "/allbets"
