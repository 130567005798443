import React, { useEffect, useState } from "react"
import {
    getQuarterTree,
    getSemiTree,
    getFinalTree,
    getWinner,
} from "../utils/getKnockoutTree"
import { Col, Container, Image, ListGroup, Row, Button } from "react-bootstrap"
import "../static/index.css"

const KnockoutStageContainer = ({
    knockoutMatches,
    setKnockoutMatches,
    quarterMatches,
    setQuarterMatches,
    semiMatches,
    setSemiMatches,
    finalMatch,
    setFinalMatch,
    winner,
    setWinner,
    dataLoading,
}) => {
    const isFilled = (matches) => {
        let fail = false
        if (!matches.length) {
            return false
        }
        matches.forEach((match) => {
            match.teams.forEach((team) => {
                if (team === null) {
                    fail = true
                }
            })
        })
        if (fail) return false
        return true
    }

    const handleKnockoutClick = (e) => {
        const match = knockoutMatches.find(
            (match) => match.id == e.currentTarget.getAttribute("match")
        )

        if (!isFilled(knockoutMatches)) return

        const winner = parseInt(e.currentTarget.getAttribute("data-id"))
        const index = knockoutMatches.indexOf(match)

        const newKnockoutMatches = Array.from(knockoutMatches)
        let obj = newKnockoutMatches[index]
        obj.winner = winner
        newKnockoutMatches[index] = obj

        setKnockoutMatches(newKnockoutMatches)
    }
    const handleQuarterClick = (e) => {
        const match = quarterMatches.find(
            (match) => match.id == e.currentTarget.getAttribute("match")
        )

        if (!isFilled(quarterMatches)) return

        const winner = parseInt(e.currentTarget.getAttribute("data-id"))
        const index = quarterMatches.indexOf(match)

        const newQuarterMatches = Array.from(quarterMatches)
        let obj = newQuarterMatches[index]
        obj.winner = winner
        newQuarterMatches[index] = obj

        setQuarterMatches(newQuarterMatches)
    }
    const handleSemiClick = (e) => {
        const match = semiMatches.find(
            (match) => match.id == e.currentTarget.getAttribute("match")
        )

        if (!isFilled(semiMatches)) return

        const winner = parseInt(e.currentTarget.getAttribute("data-id"))
        const index = semiMatches.indexOf(match)

        const newSemiMatches = Array.from(semiMatches)
        let obj = newSemiMatches[index]
        obj.winner = winner
        newSemiMatches[index] = obj

        setSemiMatches(newSemiMatches)
    }

    const handleFinalClick = (e) => {
        const match = finalMatch.find(
            (match) => match.id == e.currentTarget.getAttribute("match")
        )

        if (!isFilled(finalMatch)) return

        const winner = parseInt(e.currentTarget.getAttribute("data-id"))
        const index = finalMatch.indexOf(match)

        const newFinalMatch = Array.from(finalMatch)
        let obj = newFinalMatch[index]
        obj.winner = winner
        newFinalMatch[index] = obj

        setFinalMatch(newFinalMatch)
    }

    useEffect(() => {
        if (!dataLoading) {
            if (isFilled(knockoutMatches)) {
                const quarterTree = getQuarterTree(knockoutMatches)
                setQuarterMatches(quarterTree)
            } else {
                setQuarterMatches([])
            }
        }
    }, [knockoutMatches])

    useEffect(() => {
        if (!dataLoading) {
            if (isFilled(quarterMatches)) {
                const semiTree = getSemiTree(quarterMatches)
                setSemiMatches(semiTree)
            } else {
                setSemiMatches([])
            }
        }
    }, [quarterMatches])

    useEffect(() => {
        if (!dataLoading) {
            if (isFilled(semiMatches)) {
                const finalTree = getFinalTree(semiMatches)
                setFinalMatch(finalTree)
            } else {
                setFinalMatch([])
            }
        }
    }, [semiMatches])

    useEffect(() => {
        if (!dataLoading) {
            if (isFilled(finalMatch)) {
                const winnerTeam = getWinner(finalMatch)
                setWinner(winnerTeam)
            } else {
                setWinner()
            }
        }
    }, [finalMatch])

    return (
        <Container className="px-3 py-5">
            <Row className="justify-content-center text-center">
                <Col xs={12}>
                    <h1>Knockout Stage</h1>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={3} className="match-container">
                    {knockoutMatches &&
                        knockoutMatches.map((match, index) => (
                            <ListGroup className="py-3" key={index}>
                                <ListGroup.Item
                                    variant={match.winner === 1 && "light"}
                                    match={match.id}
                                    data-id={0}
                                    onClick={handleKnockoutClick}>
                                    <Row className="align-items-center">
                                        <Col
                                            sm={12}
                                            md={4}
                                            className="d-none d-md-block">
                                            <Container>
                                                <Image
                                                    fluid
                                                    src={`/images/${match.teams[0].team.toLowerCase()}.png`}
                                                />
                                            </Container>
                                        </Col>
                                        <Col sm={12} md={8}>
                                            {match.teams[0].code}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    variant={match.winner === 0 && "light"}
                                    match={match.id}
                                    data-id={1}
                                    onClick={handleKnockoutClick}>
                                    <Row className="align-items-center">
                                        <Col
                                            sm={12}
                                            md={4}
                                            className="d-none d-md-block">
                                            <Container>
                                                <Image
                                                    fluid
                                                    src={`/images/${match.teams[1].team.toLowerCase()}.png`}
                                                />
                                            </Container>
                                        </Col>
                                        <Col sm={12} md={8}>
                                            {match.teams[1].code}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        ))}
                </Col>
                <Col xs={3} className="match-container">
                    {quarterMatches &&
                        quarterMatches.map((match, index) => (
                            <ListGroup className="py-2" key={index}>
                                <ListGroup.Item
                                    variant={match.winner === 1 && "light"}
                                    match={match.id}
                                    data-id={0}
                                    onClick={handleQuarterClick}>
                                    {match.teams[0]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[0].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[0].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    variant={match.winner === 0 && "light"}
                                    match={match.id}
                                    data-id={1}
                                    onClick={handleQuarterClick}>
                                    {match.teams[1]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[1].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[1].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        ))}
                </Col>
                <Col xs={3} className="match-container">
                    {semiMatches &&
                        semiMatches.map((match, index) => (
                            <ListGroup className="py-2" key={index}>
                                <ListGroup.Item
                                    variant={match.winner === 1 && "light"}
                                    match={match.id}
                                    data-id={0}
                                    onClick={handleSemiClick}>
                                    {match.teams[0]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[0].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[0].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    variant={match.winner === 0 && "light"}
                                    match={match.id}
                                    data-id={1}
                                    onClick={handleSemiClick}>
                                    {match.teams[1]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[1].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[1].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        ))}
                </Col>
                <Col xs={3} className="match-container">
                    {finalMatch &&
                        finalMatch.map((match, index) => (
                            <ListGroup className="py-3" key={index}>
                                <ListGroup.Item
                                    variant={match.winner === 1 && "light"}
                                    match={match.id}
                                    data-id={0}
                                    onClick={handleFinalClick}>
                                    {match.teams[0]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[0].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[0].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item
                                    variant={match.winner === 0 && "light"}
                                    match={match.id}
                                    data-id={1}
                                    onClick={handleFinalClick}>
                                    {match.teams[1]?.code ? (
                                        <Row className="align-items-center">
                                            <Col
                                                sm={12}
                                                md={4}
                                                className="d-none d-md-block">
                                                <Container>
                                                    <Image
                                                        fluid
                                                        src={`/images/${match.teams[1].team.toLowerCase()}.png`}
                                                    />
                                                </Container>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                {match.teams[1].code}
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className="not-visible">
                                            placeholder
                                        </div>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        ))}
                </Col>
            </Row>
        </Container>
    )
}

export default KnockoutStageContainer
