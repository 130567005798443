import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Col, Container, Image, ListGroup, Row } from "react-bootstrap"
import { ChevronDoubleRight, GripHorizontal } from "react-bootstrap-icons"
import { useHistory, useParams } from "react-router-dom"
import { getKnockoutTree } from "../utils/getKnockoutTree"
import isAuthenticated from "../utils/isAuthenticated"

const GroupStageContainer = ({
    groups,
    setGroups,
    thirds,
    setThirds,
    setKnockoutMatches,
    dataLoading,
}) => {
    const history = useHistory()

    const token =
        window.location.hash &&
        window.location.hash.split("access_token=")[1].split("&expires")[0]

    useEffect(() => {
        if (!isAuthenticated()) {
            if (!token) {
                history.push("/login")
            } else {
                localStorage.accessToken = token
                history.push("/")
            }
        }
    })

    useEffect(() => {
        Object.entries(groups).forEach(([group, teams]) => {
            thirds.forEach((team, index) => {
                if (team.group === group) {
                    if (teams[2].code !== team.code) {
                        let items = [...thirds]
                        let item = { ...items[index] }
                        item = teams[2]
                        items[index] = item
                        if (!dataLoading) {
                            setThirds(items)
                        }
                    }
                }
            })
        })
    }, [groups])

    useEffect(() => {
        const toKnockout = {}
        Object.entries(groups).forEach(([group, teams]) => {
            const third = thirds.find((team) => team.group === group)
            toKnockout[group] = teams.slice(0, 2)
            if (
                thirds
                    .map((team) => team.code)
                    .slice(0, 4)
                    .includes(third.code)
            ) {
                toKnockout[group] = [...toKnockout[group], third]
            }
        })

        if (!dataLoading) {
            setKnockoutMatches(getKnockoutTree(toKnockout))
        }
    }, [groups, thirds])

    const handleDragEnd = (result, group) => {
        if (!result.destination) return
        const items = Array.from(groups[group])
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setGroups({ ...groups, [group]: items })
    }

    const handleThirdDragEnd = (result) => {
        if (!result.destination) return
        const items = Array.from(thirds)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setThirds(items)
    }

    return (
        <Container className="py-4">
            <Row className="justify-content-center text-center">
                <Col xs={12}>
                    <h1>Group Stage</h1>
                </Col>
            </Row>
            <Row className="justify-content-xs-center">
                {Object.entries(groups).map(([group, teams], index) => (
                    <Col xs={12} lg={6} key={index}>
                        <Container className="py-4">
                            <Row className="justify-content-center align-items-center">
                                <Col xs={8}>
                                    <h3>Group {group}</h3>
                                    <DragDropContext
                                        onDragEnd={(result) =>
                                            handleDragEnd(result, group)
                                        }>
                                        <Droppable droppableId={group}>
                                            {(provided) => (
                                                <ListGroup
                                                    key={index}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {teams.map(
                                                        (team, index) => (
                                                            <Draggable
                                                                key={team.code}
                                                                draggableId={
                                                                    team.code
                                                                }
                                                                index={index}>
                                                                {(provided) => (
                                                                    <ListGroup.Item
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        variant={
                                                                            (index <
                                                                                2 ||
                                                                                thirds
                                                                                    .map(
                                                                                        (
                                                                                            team
                                                                                        ) =>
                                                                                            team.code
                                                                                    )
                                                                                    .slice(
                                                                                        0,
                                                                                        4
                                                                                    )
                                                                                    .includes(
                                                                                        team.code
                                                                                    )) &&
                                                                            "primary"
                                                                        }>
                                                                        <Row className="align-items-center">
                                                                            <Col
                                                                                xs={
                                                                                    2
                                                                                }>
                                                                                {index +
                                                                                    1}

                                                                                .
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    2
                                                                                }>
                                                                                <Container className="p-1">
                                                                                    <Image
                                                                                        fluid
                                                                                        src={`/images/${team.team.toLowerCase()}.png`}
                                                                                    />
                                                                                </Container>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    4
                                                                                }>
                                                                                {
                                                                                    team.code
                                                                                }
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    2
                                                                                }></Col>
                                                                            <Col
                                                                                xs={
                                                                                    2
                                                                                }>
                                                                                <GripHorizontal />
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                    {provided.placeholder}
                                                </ListGroup>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                ))}
            </Row>

            <Row className="justify-content-center align-items-center">
                <Col xs={12} lg={6}>
                    <Container className="py-4">
                        <Row className="justify-content-center ">
                            <Col xs={8}>
                                <h3>Teams finished 3rd</h3>
                                <DragDropContext
                                    onDragEnd={(result) =>
                                        handleThirdDragEnd(result)
                                    }>
                                    <Droppable droppableId="thirds">
                                        {(provided) => (
                                            <ListGroup
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {thirds.map((team, index) => (
                                                    <Draggable
                                                        key={team.code}
                                                        draggableId={team.code}
                                                        index={index}>
                                                        {(provided) => (
                                                            <ListGroup.Item
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                variant={
                                                                    index < 4 &&
                                                                    "primary"
                                                                }>
                                                                <Row className="align-items-center">
                                                                    <Col xs={2}>
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <Container className="p-1">
                                                                            <Image
                                                                                fluid
                                                                                src={`/images/${team.team.toLowerCase()}.png`}
                                                                            />
                                                                        </Container>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        {
                                                                            team.code
                                                                        }
                                                                    </Col>
                                                                    <Col
                                                                        xs={
                                                                            2
                                                                        }></Col>
                                                                    <Col xs={2}>
                                                                        <GripHorizontal />
                                                                    </Col>
                                                                </Row>
                                                            </ListGroup.Item>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ListGroup>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default GroupStageContainer
