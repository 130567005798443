import {
    SUBMIT_ERROR,
    SUBMIT_LOADING,
    SUBMIT_SUCCESS,
} from "../../../constants/actionTypes"
import axiosInstance, { BET_URL } from "../../../helpers/api"

export const submit =
    (
        history,
        groupsReduced,
        knockoutReduced,
        quarterReduced,
        semiReduced,
        finalReduced,
        winnerReduced,
        groups,
        thirds,
        knockoutMatches,
        quarterMatches,
        semiMatches,
        finalMatch,
        winner,
        scorer
    ) =>
    (dispatch) => {
        dispatch({
            type: SUBMIT_LOADING,
        })

        const request = {
            data: {
                groups: groupsReduced,
                G16: knockoutReduced,
                QF: quarterReduced,
                SF: semiReduced,
                F: finalReduced,
                winner: winnerReduced,
                topScorer: scorer,
            },
            other: {
                groups,
                thirds,
                knockoutMatches,
                quarterMatches,
                semiMatches,
                finalMatch,
                winner,
                scorer,
            },
        }

        axiosInstance(history)
            .post(BET_URL, request)
            .then((res) => {
                dispatch({
                    type: SUBMIT_SUCCESS,
                    payload: res.data.other,
                })

                console.log(res.data)
            })
            .catch((err) => {
                dispatch({
                    type: SUBMIT_ERROR,
                    payload: err.response
                        ? err.response.data
                        : "Connection error",
                })

                if (err.response) {
                    if (err.response.status === 401) {
                        localStorage.removeItem("accessToken")
                        history.push("/login")
                    } else {
                        console.log(err.response.status)
                    }
                }
            })
    }
