import {
    BrowserRouter as Router,
    Link,
    Route,
    Switch,
    useHistory,
    useParams,
} from "react-router-dom"
import { GlobalProvider } from "./context/Provider"
import routes from "./routes"
import "./static/bootstrap.min.css"
import "./App.css"
import isAuthenticated from "./utils/isAuthenticated"

const RenderRoute = (route) => {
    const history = useHistory()

    document.title = route.title

    return (
        <Route
            path={route.path}
            exact={true}
            render={(props) => <route.page {...props} />}
        />
    )
}

export default function App() {
    return (
        <div className="App">
            <GlobalProvider>
                <Router>
                    <Switch>
                        {routes.map((route, key) => (
                            <RenderRoute {...route} key={key} />
                        ))}
                    </Switch>
                </Router>
            </GlobalProvider>
        </div>
    )
}
