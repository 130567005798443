import React, { useEffect, useState } from "react"
import useWindowDimensions from "../utils/useWindowDimensions"
import "../static/index.css"
import { Row, Col, Container } from "react-bootstrap"
import { Button } from "react-bootstrap"
import isAuthenticated from "../utils/isAuthenticated"
import { useHistory } from "react-router-dom"

const LoginPage = () => {
    const { width } = useWindowDimensions()
    const history = useHistory()

    useEffect(() => {
        if (isAuthenticated()) {
            history.push("/")
        }
    })

    return (
        <>
            {width > 1200 ? (
                <div className="background-image-pattern">
                    <div className="background-image-rooney">
                        <Container>
                            <Row className="justify-content-center align-items-center span">
                                <Col xs={6}></Col>
                                <Col xs={6} className="text-center">
                                    <h1 className="heading">Baromarc</h1>
                                    <Row className="justify-content-center align-items-center">
                                        <Col xs={6}>
                                            <div className="d-grid py-3 text-center">
                                                <Button
                                                    href="https://login.baromarc.xyz/login?client_id=31alp5ii4r3phh74n8q3igkvng&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https://baromarc.xyz/"
                                                    className="button-text">
                                                    LOGIN
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            ) : (
                <div className="background-image-pattern">
                    <div className="background-image-rooney"/>
                    <Container>
                        <Row className="justify-content-center align-items-center span container-row">
                            <Col xs={6} className="text-center">
                                <h1 className="heading">Baromarc</h1>
                                <Row className="justify-content-center align-items-center">
                                    <Col xs={6}>
                                        <div className="d-grid py-3 text-center">
                                            <Button
                                                href="https://login.baromarc.xyz/login?client_id=31alp5ii4r3phh74n8q3igkvng&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https://baromarc.xyz/"
                                                variant="light"
                                                className="button-text">
                                                LOGIN
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    )
}

export default LoginPage
