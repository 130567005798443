import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"
import BetsPage from "./pages/BetsPage"
import LogoutPage from "./pages/LogoutPage";

const routes = [
    {
        path: "/login",
        page: LoginPage,
        title: "Baromarc",
        needsAuth: false,
    },
    {
        path: "/logout",
        page: LogoutPage,
        title: "Logout",
        needsAuth: false,
    },
    {
        path: "/bets",
        page: BetsPage,
        title: "Baromarc Bets",
        needsAuth: false,
    },
    {
        path: "/",
        page: MainPage,
        title: "Baromarc 2024",
        needsAuth: true,
    },
]

export default routes
