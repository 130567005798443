export const reduceGroup = (groups) => {
    let groupsReduced = {}
    Object.entries(groups).forEach(([group, teams]) => {
        let groupReduced = {}
        teams.forEach((team, index) => {
            groupReduced[group + (index + 1)] = team.code
        })
        groupsReduced[group] = groupReduced
    })

    return groupsReduced
}

export const reduceKnockout = (knockoutMatches, round) => {
    let knockoutReduced = {}

    knockoutMatches.forEach((match, index) => {
        let matchReduced = {}
        match.teams.forEach((team, indexTeam) => {
            const letter = indexTeam == 0 ? "A" : "B"
            matchReduced[round + "-" + (index + 1) + letter] = team.code
        })
        knockoutReduced[index + 1] = matchReduced
    })

    return knockoutReduced
}

export const reduceWinner = (winner) => {
    return winner.code
}
