import React, { useContext, useEffect, useState } from "react"
import GroupStageContainer from "../components/GroupStageContainer"
import teams from "../utils/teams"
import { reduceGroup, reduceKnockout, reduceWinner } from "../utils/reducers"
import KnockoutStageContainer from "../components/KnockoutStageContainer"
import { submit } from "../context/actions/bet/submit"
import { GlobalContext } from "../context/Provider"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { get } from "../context/actions/bet/get"
import isAuthenticated from "../utils/isAuthenticated"

const MainPage = ({ title }) => {
    const history = useHistory()

    const {
        betDispatch,
        betState: {
            bet: { loading, data, error },
        },
    } = useContext(GlobalContext)

    const [showModal, setShowModal] = useState(false)

    const handleCloseModal = () => setShowModal(false)
    const handleShowModal = () => setShowModal(true)

    const [groups, setGroups] = useState(data.groups)
    const [thirds, setThirds] = useState(data.thirds)
    const [knockoutMatches, setKnockoutMatches] = useState(data.knockoutMatches)
    const [quarterMatches, setQuarterMatches] = useState(data.quarterMatches)
    const [semiMatches, setSemiMatches] = useState(data.semiMatches)
    const [finalMatch, setFinalMatch] = useState(data.finalMatch)
    const [winner, setWinner] = useState(data.winner)
    const [scorer, setScorer] = useState(null)
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        if (!isAuthenticated()) {
            history.push("/login")
        }

        get(history)(betDispatch)
    }, [])

    useEffect(() => {
        if (data && data.finalMatch.length) {
            setDataLoading(true)
        }
    }, [data])

    useEffect(() => {
        if (dataLoading && data.finalMatch.length) {
            setGroups(data.groups)
            setThirds(data.thirds)
            setKnockoutMatches(data.knockoutMatches)
            setQuarterMatches(data.quarterMatches)
            setSemiMatches(data.semiMatches)
            setFinalMatch(data.finalMatch)
            setWinner(data.winner)
            setScorer(data.scorer)
        }
    }, [dataLoading, data])

    useEffect(() => {
        if (!loading && scorer !== null) {
            setDataLoading(false)
        }
    })

    const onChange = (e) => {
        setScorer(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (
            !winner ||
            !scorer ||
            new Date() > new Date("June 26, 2024, 23:00:00")
        ) {
            handleShowModal()
            return
        }

        const groupsReduced = reduceGroup(groups)
        const knockoutReduced = reduceKnockout(knockoutMatches, "G16")
        const quarterReduced = reduceKnockout(quarterMatches, "QF")
        const semiReduced = reduceKnockout(semiMatches, "SF")
        const finalReduced = reduceKnockout(finalMatch, "F")
        const winnerReduced = reduceWinner(winner)

        submit(
            history,
            groupsReduced,
            knockoutReduced,
            quarterReduced,
            semiReduced,
            finalReduced,
            winnerReduced,
            groups,
            thirds,
            knockoutMatches,
            quarterMatches,
            semiMatches,
            finalMatch,
            winner,
            scorer
        )(betDispatch)

        handleShowModal()
    }

    return (
        <>
            <Row className="justify-content-end">
                <Button
                    href="/bets"
                    variant="light"
                    className="w-auto"
                >
                    BETS
                </Button>
                <Button
                    href="https://login.baromarc.xyz/logout?client_id=31alp5ii4r3phh74n8q3igkvng&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https//baromarc.xyz&logout_uri=https://baromarc.xyz/logout"
                    variant="light"
                    className="w-auto"
                >
                    LOG OUT
                </Button>
            </Row>
            <GroupStageContainer
                teams={teams}
                groups={groups}
                setGroups={setGroups}
                thirds={thirds}
                setThirds={setThirds}
                setKnockoutMatches={setKnockoutMatches}
                dataLoading={dataLoading}
            />
            <KnockoutStageContainer
                knockoutMatches={knockoutMatches}
                setKnockoutMatches={setKnockoutMatches}
                quarterMatches={quarterMatches}
                setQuarterMatches={setQuarterMatches}
                semiMatches={semiMatches}
                setSemiMatches={setSemiMatches}
                finalMatch={finalMatch}
                setFinalMatch={setFinalMatch}
                winner={winner}
                setWinner={setWinner}
                onSubmit={onSubmit}
                dataLoading={dataLoading}
            />

            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col xs={3}>
                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="scorer" className="pb-5">
                                <Form.Label>Top scorer</Form.Label>
                                <Form.Control
                                    name="scorer"
                                    type="text"
                                    placeholder="Who will be the top scorer..."
                                    value={scorer || ""}
                                    onChange={onChange}
                                />
                            </Form.Group>
                            <div className="d-grid py-3">
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onHide={handleCloseModal}>
                {!winner || !scorer ? (
                    <>
                        <Modal.Header>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please fill the tree and enter top scorer!
                        </Modal.Body>
                    </>
                ) : new Date() > new Date("June 26, 2024, 14:00:00") ? (
                    <>
                        <Modal.Header>
                            <Modal.Title>Warning</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You can no longer place your bets.
                        </Modal.Body>
                    </>
                ) : (
                    <>
                        <Modal.Header>
                            <Modal.Title>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Your bet is saved!</Modal.Body>
                    </>
                )}

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MainPage
