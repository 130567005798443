const getTreeVariant = (teams) => {
    const treeVariant = []
    Object.entries(teams).forEach(([group, teams]) => {
        if (teams.length > 2) {
            treeVariant.push(group)
        }
    })
    return treeVariant
}

const getFirst = (teams, group) => {
    return teams[group][0]
}

const getSecond = (teams, group) => {
    return teams[group][1]
}

const getGame = (teams, team) => {
    const format = getFormat(getTreeVariant(teams))

    let index = {
        B: 0,
        C: 1,
        E: 2,
        F: 3,
    }
    const group = format[1][index[team.group]]

    const opponent = teams[group][2]

    return [team, opponent]
}

const getKnockoutWinner = (knockOutTree, id) => {
    const match = knockOutTree.find((match) => match.id == id)
    const winner = match.winner

    if (match.winner !== null) {
        return match.teams[winner]
    }
    return null
}

const getQuarterWinner = (quarterTree, id) => {
    const match = quarterTree.find((match) => match.id == id)
    const winner = match.winner

    if (match.winner !== null) {
        return match.teams[winner]
    }
    return null
}

const getSemiWinner = (semiTree, id) => {
    const match = semiTree.find((match) => match.id == id)
    const winner = match.winner

    if (match.winner !== null) {
        return match.teams[winner]
    }
    return null
}

const getFinalWinner = (finalTree, id) => {
    const match = finalTree.find((match) => match.id == id)
    const winner = match.winner

    if (match.winner !== null) {
        return match.teams[winner]
    }
    return null
}

export const getKnockoutTree = (teams) => {
    const tree = [
        {
            id: 1,
            teams: getGame(teams, getFirst(teams, "B")),
            winner: null,
        },
        {
            id: 2,
            teams: [getFirst(teams, "A"), getSecond(teams, "C")],
            winner: null,
        },
        {
            id: 3,
            teams: getGame(teams, getFirst(teams, "F")),
            winner: null,
        },
        {
            id: 4,
            teams: [getSecond(teams, "D"), getSecond(teams, "E")],
            winner: null,
        },
        {
            id: 5,
            teams: getGame(teams, getFirst(teams, "E")),
            winner: null,
        },
        {
            id: 6,
            teams: [getFirst(teams, "D"), getSecond(teams, "F")],
            winner: null,
        },
        {
            id: 7,
            teams: getGame(teams, getFirst(teams, "C")),
            winner: null,
        },
        {
            id: 8,
            teams: [getSecond(teams, "A"), getSecond(teams, "B")],
            winner: null,
        },
    ]
    return tree
}

export const getQuarterTree = (knockoutMatches) => {
    const tree = [
        {
            id: 9,
            teams: [
                getKnockoutWinner(knockoutMatches, 1),
                getKnockoutWinner(knockoutMatches, 2),
            ],
            winner: null,
        },
        {
            id: 10,
            teams: [
                getKnockoutWinner(knockoutMatches, 3),
                getKnockoutWinner(knockoutMatches, 4),
            ],
            winner: null,
        },
        {
            id: 11,
            teams: [
                getKnockoutWinner(knockoutMatches, 5),
                getKnockoutWinner(knockoutMatches, 6),
            ],
            winner: null,
        },
        {
            id: 12,
            teams: [
                getKnockoutWinner(knockoutMatches, 7),
                getKnockoutWinner(knockoutMatches, 8),
            ],
            winner: null,
        },
    ]
    return tree
}

export const getSemiTree = (quarterMatches) => {
    const tree = [
        {
            id: 13,
            teams: [
                getQuarterWinner(quarterMatches, 9),
                getQuarterWinner(quarterMatches, 10),
            ],
            winner: null,
        },
        {
            id: 14,
            teams: [
                getQuarterWinner(quarterMatches, 11),
                getQuarterWinner(quarterMatches, 12),
            ],
            winner: null,
        },
    ]
    return tree
}

export const getFinalTree = (semiMatches) => {
    const tree = [
        {
            id: 15,
            teams: [
                getSemiWinner(semiMatches, 13),
                getSemiWinner(semiMatches, 14),
            ],
            winner: null,
        },
    ]
    return tree
}

export const getWinner = (finalMatch) => {
    const winner = getFinalWinner(finalMatch, 15)
    return winner
}

const getFormat = (variant) => {
    let format = ""
    switch (variant.join("")) {
        case "ABCD":
            format = "ADBC"
            break
        case "ABCE":
            format = "AEBC"
            break
        case "ABCF":
            format = "AFBC"
            break
        case "ABDE":
            format = "DEAB"
            break
        case "ABDF":
            format = "DFAB"
            break
        case "ABEF":
            format = "EFBA"
            break
        case "ACDE":
            format = "EDCA"
            break
        case "ACDF":
            format = "FDCA"
            break
        case "ACEF":
            format = "EFCA"
            break
        case "ADEF":
            format = "EFDA"
            break
        case "BCDE":
            format = "EDBC"
            break
        case "BCDF":
            format = "FDCB"
            break
        case "BCEF":
            format = "FECB"
            break
        case "BDEF":
            format = "FEDB"
            break
        case "CDEF":
            format = "FEDC"
            break
    }

    return [Array.from(variant), Array.from(format)]
}
