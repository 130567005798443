import { useEffect } from "react"
import {useHistory} from "react-router-dom";
import isAuthenticated from "../utils/isAuthenticated";
const LoginPage = () => {
    const history = useHistory()

    useEffect(() => {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")

        history.push("/")
    }, [])

    return null
}

export default LoginPage
