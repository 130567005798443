const teams = [
    {
        team: "Germany",
        code: "GER",
        group: "A",
    },
    {
        team: "Scotland",
        code: "SCO",
        group: "A",
    },
    {
        team: "Hungary",
        code: "HUN",
        group: "A",
    },
    {
        team: "Switzerland",
        code: "SUI",
        group: "A",
    },
    {
        team: "Spain",
        code: "ESP",
        group: "B",
    },
    {
        team: "Croatia",
        code: "CRO",
        group: "B",
    },
    {
        team: "Italy",
        code: "ITA",
        group: "B",
    },
    {
        team: "Albania",
        code: "ALB",
        group: "B",
    },
    {
        team: "Slovenia",
        code: "SLO",
        group: "C",
    },
    {
        team: "Denmark",
        code: "DEN",
        group: "C",
    },
    {
        team: "Serbia",
        code: "SRB",
        group: "C",
    },
    {
        team: "England",
        code: "ENG",
        group: "C",
    },
    {
        team: "Poland",
        code: "POL",
        group: "D",
    },
    {
        team: "Netherlands",
        code: "NED",
        group: "D",
    },
    {
        team: "Austria",
        code: "AUT",
        group: "D",
    },
    {
        team: "France",
        code: "FRA",
        group: "D",
    },
    {
        team: "Belgium",
        code: "BEL",
        group: "E",
    },
    {
        team: "Slovakia",
        code: "SLK",
        group: "E",
    },
    {
        team: "Romania",
        code: "ROM",
        group: "E",
    },
    {
        team: "Ukraine",
        code: "UKR",
        group: "E",
    },
    {
        team: "Turkey",
        code: "TUR",
        group: "F",
    },
    {
        team: "Georgia",
        code: "GEO",
        group: "F",
    },
    {
        team: "Portugal",
        code: "POR",
        group: "F",
    },
    {
        team: "CzechRep",
        code: "CZE",
        group: "F",
    },
]

export default teams
