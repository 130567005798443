import {
    GET_ERROR,
    GET_LOADING,
    GET_SUCCESS,
    SUBMIT_ERROR,
    SUBMIT_LOADING,
    SUBMIT_SUCCESS,
} from "../../constants/actionTypes"

const bet = (state, { payload, type }) => {
    switch (type) {
        case SUBMIT_LOADING:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    error: false,
                    loading: true,
                },
            }

        case SUBMIT_SUCCESS:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    loading: false,
                    data: payload,
                },
            }

        case SUBMIT_ERROR:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    loading: false,
                    error: payload,
                },
            }
        case GET_LOADING:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    error: false,
                    loading: true,
                },
            }

        case GET_SUCCESS:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    loading: false,
                    data: payload,
                },
            }

        case GET_ERROR:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    loading: false,
                    error: payload,
                },
            }
    }
}

export default bet
