export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const SUBMIT_LOADING = "SUBMIT_LOADING"
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS"
export const SUBMIT_ERROR = "SUBMIT_ERROR"

export const GET_LOADING = "GET_LOADING"
export const GET_SUCCESS = "GET_SUCCESS"
export const GET_ERROR = "GET_ERROR"
