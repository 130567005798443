import teams from "../../utils/teams"

const defaultGroups = teams.reduce((groups, item) => {
    const group = groups[item.group] || []
    group.push(item)
    groups[item.group] = group
    return groups
}, {})

let defaultThirds = []
Object.values(defaultGroups).map((teams) => {
    defaultThirds.push(teams[2])
})

export const data = {
    groups: defaultGroups,
    thirds: defaultThirds,
    knockoutMatches: [],
    quarterMatches: [],
    semiMatches: [],
    finalMatch: [],
    winner: [],
    scorer: " ",
}

const state = {
    bet: {
        loading: false,
        data: data,
        error: null,
    },
}

export default state
