import React, { createContext, useReducer } from "react"
import auth from "./reducers/auth"
import bet from "./reducers/bet"
import authInitialState from "./initialstates/authInitialState"
import betInitialState from "./initialstates/betInititalState"

export const GlobalContext = createContext({})
export const GlobalProvider = ({ children }) => {
    const [authState, authDispatch] = useReducer(auth, authInitialState)
    const [betState, betDispatch] = useReducer(bet, betInitialState)

    return (
        <GlobalContext.Provider
            value={{ authState, authDispatch, betState, betDispatch }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
