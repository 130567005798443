import {
    GET_ERROR,
    GET_LOADING,
    GET_SUCCESS,
} from "../../../constants/actionTypes"
import axiosInstance, { BET_URL } from "../../../helpers/api"
import { data } from "../../initialstates/betInititalState"

export const get = (history) => (dispatch) => {
    dispatch({
        type: GET_LOADING,
    })

    axiosInstance()
        .get(BET_URL)
        .then((res) => {
            if (res.data.bets[0].other) {
                dispatch({
                    type: GET_SUCCESS,
                    payload: res.data.bets[0].other,
                })
            } else {
                dispatch({
                    type: GET_SUCCESS,
                    payload: data,
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: GET_ERROR,
                payload: err.response ? err.response.data : "Connection error",
            })

            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("accessToken")
                    history.push("/login")
                } else {
                    console.log(err.response.status)
                }
            }
        })
}
